'use client'

import FormButtons from '@tebuto/forms/Buttons'
import FormCheckbox from '@tebuto/forms/Checkbox'
import ErrorMessage from '@tebuto/forms/ErrorMessage'
import FormField from '@tebuto/forms/Field'
import FormTextArea from '@tebuto/forms/TextArea'
import { UNICODE_EMAIL_REGEX } from '@tebuto/functions/validate'
import { CaptchaContext, CaptchaProvider, CaptchaWidget } from '@tebuto/providers/CaptchaProvider'
import { Form, Formik } from 'formik'
import Link from 'next/link'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { boolean, object, string } from 'yup'
import { useSendContactForm } from './hooks'

export default function ContactForm() {
    return (
        <div className="md:px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
            <CaptchaProvider>
                <ContactFormContent />
            </CaptchaProvider>
        </div>
    )
}

function ContactFormContent() {
    const [error, setError] = useState<string | null>(null)
    const [submitted, setSubmitted] = useState<boolean>(false)
    const sendContactForm = useSendContactForm(setError, setSubmitted)
    const { captcha } = useContext(CaptchaContext)

    function send(values: ContactFormValues) {
        if (captcha) {
            sendContactForm({ ...values, captcha })
        } else {
            toast.error('Kein Captcha gefunden')
        }
    }

    return (
        <Formik
            initialValues={{ name: '', email: '', message: '', agreeToDataPrivacy: false }}
            validateOnChange={false}
            validateOnBlur={true}
            validationSchema={contactFormValidationSchema}
            onSubmit={send}
        >
            {({ dirty, isSubmitting, values }) => {
                if (submitted) {
                    return (
                        <div className="flex items-center py-12 sm:py-16 lg:px-8 mx-auto max-w-xl lg:mr-0">
                            {error ? (
                                <p className="text-red-400 bg-red-50 px-8 py-4 rounded-lg border-red-500 border mt-12 lg:mt-0">{error}</p>
                            ) : (
                                <p className="text-primary-700 bg-primary-50 px-8 py-4 rounded-lg border-primary-500 border mt-12 lg:mt-0">
                                    <b>Vielen Dank für Ihre Nachricht.</b>
                                    <br />
                                    Wir werden uns schnellstmöglich bei Ihnen melden.
                                </p>
                            )}
                        </div>
                    )
                }
                return (
                    <Form name={'Kontakt'}>
                        <div className="mx-auto max-w-full">
                            <div className="grid gap-6">
                                <FormField label={'Name'} name={'name'} mandatory={true} autoComplete={'name'} />
                                <FormField label={'E-Mail'} name={'email'} mandatory={true} type={'email'} autoComplete={'email'} />
                                <FormTextArea label={'Ihre Nachricht'} name={'message'} rows={4} />
                            </div>
                            <div className={'flex items-start pt-8 pb-8 mb-4'}>
                                <FormCheckbox name={'agreeToDataPrivacy'} mandatory={true} showErrors={false} className={'flex items-center'} />
                                <div className="ml-3 text-sm leading-6">
                                    <label htmlFor="agreeToDataPrivacy" className="text-gray-500 dark:text-gray-300">
                                        Ich stimme der Verarbeitung meiner Daten zum Zweck der Kontaktaufnahme durch Tebuto zu.{' '}
                                        <Link href="/datenschutzerklaerung" className="text-primary underline">
                                            Datenschutzerklärung
                                        </Link>
                                    </label>
                                    <div className="relative">
                                        <ErrorMessage name={'agreeToDataPrivacy'} />
                                    </div>
                                </div>
                            </div>
                            <FormButtons
                                showResetButton={false}
                                saveButtonEnabled={values.agreeToDataPrivacy && dirty && !isSubmitting}
                                saveButtonLabel={'Nachricht Absenden'}
                                loading={isSubmitting}
                            />
                        </div>
                        <CaptchaWidget />
                    </Form>
                )
            }}
        </Formik>
    )
}

interface ContactFormValues {
    name: string
    email: string
    message: string
    agreeToDataPrivacy: boolean
}

const contactFormValidationSchema = object().shape({
    name: string().required('Pflichtfeld'),
    // default email validation does not support unicode characters
    email: string().required('Pflichtfeld').matches(UNICODE_EMAIL_REGEX, {
        message: 'Ungültige E-Mail-Adresse',
        name: 'email',
        excludeEmptyString: true
    }),
    message: string(),
    agreeToDataPrivacy: boolean().isTrue('Sie müssen der Verarbeitung Ihrer Daten zustimmen.')
})
